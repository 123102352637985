import {
  AccessTime,
  CalendarToday as CalendarIcon,
  Cancel,
  CheckCircle,
  Close as CloseIcon,
  DirectionsWalk,
  ExitToApp,
  Warning,
} from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import FaultyCheckoutDetails from "./FaultyCheckoutDetails";
import {
  AppointmentData,
  AppointmentDistribution,
  BranchWaitTimes,
  Department,
  FaultyCheckout,
  Hospital,
  Patient,
  WaitTimes,
  WalkInData,
} from "./models";
import PatientsWaiting, { getWaitTimeClass } from "./PatientsWaiting";
import { getPreBookedAvgCheckedInWait, getPreBookedAvgWait } from "./processor";

// Hospital data extracted from the provided JSON
export const hospitalData: Hospital[] = [
  {
    id: "1a1975df-6a3a-4660-b66a-c1b64267ef98",
    name: "Max Hospital - Vaishali",
  },
  {
    id: "62b7ae4f-fed7-43e9-bbfa-39eb8a749e8a",
    name: "Max Hospital - Shalimar Bagh",
  },
  {
    id: "a622baa4-d8e9-45ad-88a7-b3c81a8f3c18",
    name: "Max Hospital - Patparganj",
  },
  {
    id: "1425c0fa-a6ca-47d6-b5e7-0fe0e9fd80a6",
    name: "Max Hospital - Saket East",
  },
  { id: "eba947d7-6faa-4236-ab61-d442cf54a528", name: "BLK Max Hospital" },
  { id: "7840d8c6-3509-48d6-8ea3-4bed103c6dcc", name: "Lajpat Nagar-MICC" },
  { id: "28f97deb-01bc-4d87-9234-48411be68ca8", name: "Max Hospital - Mohali" },
  {
    id: "95eb2416-3919-48a0-9a6c-75da506361c0",
    name: "Max Hospital - Dehradun",
  },
  { id: "62a84dbe-2d9d-458a-9c3c-e3b9f149e92e", name: "Max Hospital - Noida" },
  {
    id: "bb2a3651-cb02-41aa-9fb3-1d11854973e0",
    name: "Max Hospital - Gurgaon",
  },
  {
    id: "a7872cf4-25fa-4894-8309-73eb316154fb",
    name: "Max Hospital - Bathinda",
  },
  {
    id: "73a89ef4-e542-42c1-8189-392f9a1b10a0",
    name: "Max Hospital - Saket Smart",
  },
  {
    id: "54406929-5705-4308-a843-0082e05a8623",
    name: "Max Medcentre - Lajpat Nagar",
  },
  {
    id: "04b55f72-3aae-4c70-ad6e-3f2d2074c8ea",
    name: "Max Hospital - Panchsheel Park",
  },
  {
    id: "3ac21495-2d90-4978-8537-51d7f698d8f3",
    name: "Max Hospital - Saket West",
  },
  { id: "88587db5-8539-4056-ae01-dd249c949a77", name: "Nanavati Max Hospital" },
  {
    id: "314e972d-1014-4a71-b5b1-693e63fc661f",
    name: "Max Hospital - Noida, Sec 128",
  },
  { id: "17d6c290-0f12-4e27-b9e9-09ee2f2598ce", name: "Max Hospital - Nagpur" },
  {
    id: "cbb3329e-d89d-4bd2-b64d-e46e20fc018b",
    name: "Max Hospital - Lucknow",
  },
  { id: "05478906-a974-4f78-84a2-38ce85c39206", name: "Max Hospital - Dwarka" },
];

interface PatientCommandCenterProps {
  appointmentData: AppointmentData;
  walkInData: WalkInData;
  patients: Patient[];
  waitTimes: WaitTimes;
  departments: Department[];
  appointmentDistribution: AppointmentDistribution[];
  yesterdayAppointmentDistribution: AppointmentDistribution[];
  sevenDaysAgoAppointmentDistribution: AppointmentDistribution[];
  branchWaitTimes: BranchWaitTimes[];
  yesterdayBranchWaitTimes: BranchWaitTimes[];
  sevenDaysAgoBranchWaitTimes: BranchWaitTimes[];
  faultyCheckouts: FaultyCheckout[];
}

const PatientCommandCenter: React.FC<PatientCommandCenterProps> = ({
  appointmentData,
  walkInData,
  patients,
  waitTimes,
  departments,
  appointmentDistribution,
  yesterdayAppointmentDistribution,
  sevenDaysAgoAppointmentDistribution,
  branchWaitTimes,
  yesterdayBranchWaitTimes,
  sevenDaysAgoBranchWaitTimes,
  faultyCheckouts,
}) => {
  const [isWalkInDialogOpen, setIsWalkInDialogOpen] = useState(false);
  const [showTodayWaitTime, setShowTodayWaitTime] = useState(false);
  const [showFaultyCheckouts, setShowFaultyCheckouts] = useState(false);

  const preBookedAvgWait = getPreBookedAvgWait(appointmentData, walkInData);

  const preBookedAvgCheckedInWait = getPreBookedAvgCheckedInWait(
    appointmentData,
    walkInData
  );

  const avgWait = Math.round(preBookedAvgWait);
  const avgCheckedInWait = Math.round(preBookedAvgCheckedInWait);

  // if no faulty checkouts, set count to 0
  const faultyCheckoutsCount = faultyCheckouts.filter(
    (fc) => fc.prescription_ready_time
  ).length;

  const handleWalkInClick = () => {
    setIsWalkInDialogOpen(true);
  };

  const handleCloseWalkInDialog = () => {
    setIsWalkInDialogOpen(false);
  };

  const handleWaitTimeToggle = () => {
    setShowTodayWaitTime(!showTodayWaitTime);
  };

  const handleFaultyCheckoutsClick = () => {
    setShowFaultyCheckouts(true);
  };

  const handleCloseFaultyCheckouts = () => {
    setShowFaultyCheckouts(false);
  };

  return (
    <div className="bg-black text-white min-h-screen">
      <div>
        <div className="flex flex-col lg:flex-row gap-2">
          {/* Left column - Stats and Patients Waiting */}
          <div className="w-full lg:w-2/3 flex flex-col gap-2">
            {/* Appointment Stats */}
            <div className="bg-gray-900 rounded-lg p-4 shadow-lg">
              <h2 className="text-lg font-semibold mb-2">Appointment Stats</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
                <StatCard
                  icon={<CalendarIcon />}
                  label="All"
                  value={appointmentData.all}
                />
                <div onClick={handleWalkInClick} className="cursor-pointer">
                  <StatCard
                    icon={<DirectionsWalk />}
                    label="Walk-Ins"
                    value={
                      <>
                        {walkInData.count}
                        <span
                          className={`ml-1 ${getWalkInPercentageColor(
                            parseInt(walkInData.percentage)
                          )}`}
                        >
                          ({walkInData.percentage})
                        </span>
                      </>
                    }
                  />
                </div>
                <StatCard
                  icon={<CheckCircle />}
                  label="Checked-In"
                  value={appointmentData.checkedIn}
                />
                <StatCard
                  icon={<Cancel />}
                  label="Cancelled"
                  value={appointmentData.cancelled}
                />
                <HorizontalCheckoutCard
                  checkedOut={appointmentData.checkedOut}
                  faultyCheckoutsCount={faultyCheckoutsCount}
                  onFaultyCheckoutsClick={handleFaultyCheckoutsClick}
                />
              </div>
            </div>

            {/* Patients Waiting */}
            <div className="bg-gray-900 rounded-lg p-2 shadow-lg flex-grow overflow-auto">
              <PatientsWaiting patients={patients} waitTimes={waitTimes} />
            </div>
          </div>

          {/* Right column - Graphs and Department Information */}
          <div className="w-full lg:w-1/3 flex flex-col gap-2 lg:h-[calc(100vh-5rem)]">
            {/* Appointments Distribution */}
            <div className="bg-gray-900 rounded-lg p-2 shadow-lg lg:h-1/3">
              <AppointmentDistributionView
                todayData={appointmentDistribution}
                yesterdayData={yesterdayAppointmentDistribution}
                sevenDaysAgoData={sevenDaysAgoAppointmentDistribution}
              />
            </div>

            {/* Branch Wait Time Graph */}
            <div className="bg-gray-900 rounded-lg p-2 shadow-lg lg:h-1/3">
              <BranchWaitTimeGraphView
                branchWaitTimes={branchWaitTimes}
                yesterdayBranchWaitTimes={yesterdayBranchWaitTimes}
                sevenDaysAgoBranchWaitTimes={sevenDaysAgoBranchWaitTimes}
              />
            </div>

            <div className="bg-gray-900 rounded-lg p-2 shadow-lg">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold">Wait Time Overview</h2>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showTodayWaitTime}
                      onChange={handleWaitTimeToggle}
                      color="primary"
                      size="small"
                    />
                  }
                  label={showTodayWaitTime ? "Today" : "Current"}
                  className="text-xs"
                />
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                <WaitTimeCard
                  title="All"
                  avgWait={
                    showTodayWaitTime
                      ? appointmentData.avgWaitTime
                      : appointmentData.avgCheckedInWaitTime
                  }
                />
                <WaitTimeCard
                  title="Pre-booked"
                  avgWait={showTodayWaitTime ? avgWait : avgCheckedInWait}
                />
                <WaitTimeCard
                  title="Walk-ins"
                  avgWait={
                    showTodayWaitTime
                      ? walkInData.avgWaitTime
                      : walkInData.avgCheckedInWaitTime
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Walk-Ins by Department Dialog */}
      <Dialog
        open={isWalkInDialogOpen}
        onClose={handleCloseWalkInDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#1F2937", // dark background
            color: "white",
            minHeight: "60vh", // Set a minimum height
          },
        }}
      >
        <DialogTitle className="flex justify-between items-center">
          <span>Walk-Ins by Department</span>
          <IconButton
            onClick={handleCloseWalkInDialog}
            size="small"
            style={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="h-[50vh]">
            {" "}
            {/* Set a fixed height for the chart container */}
            <WalkInsByDepartment departments={departments} />
          </div>
        </DialogContent>
      </Dialog>

      {/* Faulty checkin dialog */}
      <Dialog
        open={showFaultyCheckouts}
        onClose={handleCloseFaultyCheckouts}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#111827", // Darker background
            color: "white",
            minHeight: "80vh",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle className="flex justify-between items-center bg-gray-900 border-b border-gray-800">
          <span>Checkout Anomalies</span>
          <IconButton
            onClick={handleCloseFaultyCheckouts}
            size="small"
            style={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-0 bg-gray-900">
          <div className="h-full">
            <FaultyCheckoutDetails checkouts={faultyCheckouts} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const StatCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: number | React.ReactNode;
}> = ({ icon, label, value }) => (
  <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center justify-center h-full">
    <div className="text-3xl mb-2">{icon}</div>
    <div className="text-sm mb-1">{label}</div>
    <div className="text-xl sm:text-2xl md:text-2xl lg:text-3xl font-bold">
      {value}
    </div>
  </div>
);

export const HorizontalCheckoutCard: React.FC<{
  checkedOut: number;
  faultyCheckoutsCount: number;
  onFaultyCheckoutsClick: () => void;
}> = ({ checkedOut, faultyCheckoutsCount, onFaultyCheckoutsClick }) => {
  const faultyPercentage =
    faultyCheckoutsCount > 0 && checkedOut > 0
      ? Math.round((faultyCheckoutsCount / checkedOut) * 100)
      : 0;

  return (
    <div
      className="bg-gray-800 p-4 rounded-lg flex flex-col items-center justify-center h-full relative cursor-pointer"
      onClick={onFaultyCheckoutsClick}
    >
      <StatCard icon={<ExitToApp />} label="Checked Out" value={checkedOut} />
      {faultyCheckoutsCount > 0 && (
        <div className="absolute bottom-1 right-1 flex items-center hover:bg-gray-700 p-1 rounded text-xs">
          <Warning className="text-yellow-500 mr-1 h-4 w-4" />

          <span className="text-yellow-500">
            {faultyCheckoutsCount} ({faultyPercentage}%)
          </span>
        </div>
      )}
    </div>
  );
};

const WaitTimeCard: React.FC<{ title: string; avgWait: number }> = ({
  title,
  avgWait,
}) => (
  <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
    <h3 className="text-lg font-semibold mb-3 text-center">{title}</h3>

    <div className="flex flex-col items-center">
      <AccessTime className="mb-1" />

      <span className={`text-lg font-bold ${getWaitTimeClass(avgWait)}`}>
        {avgWait} min
      </span>
    </div>
  </div>
);

const AppointmentDistributionView: React.FC<{
  todayData: AppointmentDistribution[];
  yesterdayData: AppointmentDistribution[];
  sevenDaysAgoData: AppointmentDistribution[];
}> = ({ todayData = [], yesterdayData = [], sevenDaysAgoData = [] }) => {
  const data = todayData.map((slot, index) => ({
    time: slot.time,
    today: slot.percentage,
    yesterday: yesterdayData[index]?.percentage || 0,
    sevenDaysAgo: sevenDaysAgoData[index]?.percentage || 0,
  }));

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center mb-2">
        <CalendarIcon className="mr-2" />

        <h3 className="text-lg font-semibold">Appointment Distribution</h3>
      </div>
      <div className="flex-grow">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
          >
            <XAxis
              dataKey="time"
              tick={{ fill: "#bbb", fontSize: 10 }}
              interval="preserveStartEnd"
            />

            <YAxis tick={{ fill: "#fff", fontSize: 10 }} />

            <Tooltip
              contentStyle={{
                backgroundColor: "#2c2c2c",
                border: "none",
                borderRadius: "4px",
                color: "#fff",
              }}
            />

            <Legend />
            <Line
              type="monotone"
              dataKey="today"
              stroke="#8884d8"
              strokeWidth={2}
              dot={false}
            />

            <Line
              type="monotone"
              dataKey="yesterday"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={false}
            />

            <Line
              type="monotone"
              dataKey="sevenDaysAgo"
              stroke="#ffc658"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const BranchWaitTimeGraphView: React.FC<{
  branchWaitTimes: BranchWaitTimes[];
  yesterdayBranchWaitTimes: BranchWaitTimes[];
  sevenDaysAgoBranchWaitTimes: BranchWaitTimes[];
}> = ({
  branchWaitTimes,
  yesterdayBranchWaitTimes,
  sevenDaysAgoBranchWaitTimes,
}) => {
  const [showOnlyToday, setShowOnlyToday] = useState(true);
  const formatHour = (hour: number): string => {
    if (hour === 12) return "12pm";
    if (hour > 12) return `${hour - 12}pm`;
    return `${hour}am`;
  };

  const processData = (
    inputData: BranchWaitTimes[]
  ): { [key: string]: number[] } => {
    const hourlyData: { [key: string]: number[] } = {};
    for (let i = 8; i <= 21; i++) {
      hourlyData[i.toString()] = [];
    }

    inputData.forEach((item) => {
      const [, timePart] = item.timestamp.split("T");
      const hour = parseInt(timePart.split(":")[0]);
      if (hourlyData[hour.toString()]) {
        hourlyData[hour.toString()].push(item.wait_time_avg);
      }
    });

    return hourlyData;
  };

  const getWaitTimeClass = (waitTime: number): string => {
    if (waitTime > 45) return "text-red-500";
    if (waitTime > 30) return "text-yellow-500";
    return "text-green-500";
  };

  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;
    if (value === null) return null;

    const waitTimeClass = getWaitTimeClass(value);
    let fillColor;

    switch (waitTimeClass) {
      case "text-red-500":
        fillColor = "#EF4444";
        break;
      case "text-yellow-500":
        fillColor = "#F59E0B";
        break;
      case "text-green-500":
        fillColor = "#10B981";
        break;
      default:
        fillColor = "#8884d8";
    }

    return <circle cx={cx} cy={cy} r={3} fill={fillColor} />;
  };

  const todayHourlyData = processData(branchWaitTimes);
  const yesterdayHourlyData = processData(yesterdayBranchWaitTimes);
  const sevenDaysAgoHourlyData = processData(sevenDaysAgoBranchWaitTimes);

  const formattedData = Object.entries(todayHourlyData).map(
    ([hour, values]) => ({
      timestamp: hour,
      displayTime: formatHour(parseInt(hour)),
      today:
        values.length > 0
          ? Math.round(
              values.reduce((sum, value) => sum + value, 0) / values.length
            )
          : null,
      yesterday:
        !showOnlyToday && yesterdayHourlyData[hour].length > 0
          ? Math.round(
              yesterdayHourlyData[hour].reduce((sum, value) => sum + value, 0) /
                yesterdayHourlyData[hour].length
            )
          : null,
      sevenDaysAgo:
        !showOnlyToday && sevenDaysAgoHourlyData[hour].length > 0
          ? Math.round(
              sevenDaysAgoHourlyData[hour].reduce(
                (sum, value) => sum + value,
                0
              ) / sevenDaysAgoHourlyData[hour].length
            )
          : null,
    })
  );

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyToday(event.target.checked);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center mb-2">
          <CalendarIcon className="mr-2" />

          <h3 className="text-lg font-semibold">Patient Wait Time</h3>
        </div>

        <FormControlLabel
          control={
            <Switch
              checked={showOnlyToday}
              onChange={handleToggle}
              color="primary"
              size="small"
            />
          }
          label="Today"
          className="text-xs"
        />
      </div>
      <div className="flex-grow" style={{ minHeight: "200px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={formattedData}
            margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
          >
            <XAxis
              dataKey="displayTime"
              tick={{ fill: "#bbb", fontSize: 10 }}
              interval="preserveStartEnd"
            />

            <YAxis
              tick={{ fill: "#fff", fontSize: 10 }}
              // label={{ value: 'Avg Wait Time (mins)', angle: -90, position: 'insideBottomLeft', fill: '#fff', fontSize: 12 }}
            />

            <Tooltip
              contentStyle={{
                backgroundColor: "#2c2c2c",
                border: "none",
                borderRadius: "4px",
                color: "#fff",
              }}
            />

            <Legend />

            <Line
              type="monotone"
              dataKey="today"
              name="Today"
              stroke="#8884d8"
              strokeWidth={2}
              dot={(props) => <CustomizedDot {...props} />}
              connectNulls={false}
            />
            {!showOnlyToday && (
              <>
                <Line
                  type="monotone"
                  dataKey="yesterday"
                  name="Yesterday"
                  stroke="#82ca9d"
                  strokeWidth={2}
                  dot={false}
                  connectNulls={false}
                />

                <Line
                  type="monotone"
                  dataKey="sevenDaysAgo"
                  name="7 Days Ago"
                  stroke="#ffc658"
                  strokeWidth={2}
                  dot={false}
                  connectNulls={false}
                />
              </>
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const WalkInsByDepartment: React.FC<{ departments: Department[] }> = ({
  departments,
}) => {
  // Sort departments by percentage in decreasing order and take the first five
  const sortedDepartments = [...departments]
    .sort((a, b) => b.percentage - a.percentage)
    // remove any departments with 0% walk-ins
    .filter((department) => department.percentage > 0);

  const generateRedToGreenShades = (numOfShades: number, threshold: number) => {
    const shades = [];
    const startColor = [255, 0, 0]; // RGB for red
    const endColor = [0, 128, 0]; // RGB for green
    for (let i = 0; i < numOfShades; i++) {
      const ratio = i / (numOfShades - 1);
      const color = startColor.map((start, index) =>
        Math.round(start + ratio * (endColor[index] - start))
      );
      const isAboveThreshold = departments[i].percentage > threshold;
      // Keep shades more on the red side if above threshold
      if (isAboveThreshold) {
        const adjustedColor = startColor.map((start, index) =>
          Math.round(start + 0.5 * ratio * (endColor[index] - start))
        );
        shades.push(
          `rgb(${adjustedColor[0]}, ${adjustedColor[1]}, ${adjustedColor[2]})`
        );
      } else {
        shades.push(`rgb(${color[0]}, ${color[1]}, ${color[2]})`);
      }
    }
    return shades;
  };

  const redToGreenShades = generateRedToGreenShades(
    sortedDepartments.length,
    20
  );

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center mb-4">
        <DirectionsWalk className="mr-2" />
        <h3 className="text-lg font-semibold">Walk-Ins by Department</h3>
      </div>
      <div className="flex-grow">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={sortedDepartments}
            layout="vertical"
            margin={{ top: 5, right: 30, left: 40, bottom: 5 }}
          >
            <XAxis type="number" domain={[0, 100]} />

            <YAxis
              type="category"
              dataKey="name"
              width={100}
              tick={{ fill: "#fff", fontSize: 10 }}
            />

            <Tooltip
              contentStyle={{
                backgroundColor: "#2c2c2c",
                border: "none",
                borderRadius: "4px",
                color: "#fff",
              }}
              formatter={(value) => `${value}%`}
            />

            <Bar dataKey="percentage" fill="#8884d8">
              {sortedDepartments.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={redToGreenShades[index]} />
              ))}

              <LabelList
                dataKey="percentage"
                position="right"
                formatter={(value: number) => `${value}%`}
                style={{ fill: "white" }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const getWalkInPercentageColor = (percentage: number) => {
  if (percentage < 20) return "text-green-500";
  if (percentage < 30) return "text-yellow-500";
  return "text-red-500";
};

export default PatientCommandCenter;
