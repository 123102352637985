import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { PAUSE_ESCALATION_REASONS } from "../helpers/constants";
import { pauseEscalationsStatus, TPauseReasons } from "./fetchEscalations";
import { useCallback, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { titleCase } from "../helpers";

interface IPauseModalProps {
  open: boolean;
  handleClose: () => void;
  defaultReason?: TPauseReasons;
  setPausedEscalations: React.Dispatch<
    React.SetStateAction<
      Array<{
        appointmentId: string;
        pausedReason: TPauseReasons;
      }>
    >
  >;
  appointmentId: string | null;
  patientName: string | null;
  updateEscalationsTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      background: "#31363F",
    },
  },
};

const PauseModal = ({
  open,
  handleClose,
  defaultReason,
  appointmentId,
  patientName,
  setPausedEscalations,
  updateEscalationsTrigger,
}: IPauseModalProps) => {
  const [selectedReason, setSelectedReason] = useState<{
    label: string;
    value: TPauseReasons;
  } | null>(
    PAUSE_ESCALATION_REASONS.find((d) => d.value === defaultReason) ?? null
  );

  const handleReasonChange = (event: SelectChangeEvent<TPauseReasons>) => {
    const {
      target: { value },
    } = event;

    setSelectedReason(
      PAUSE_ESCALATION_REASONS.find((d) => d.value === value) ?? null
    );
  };

  const handleOnClose = useCallback(() => {
    handleClose?.();
    setSelectedReason(null);
  }, [handleClose]);

  const handleSubmit = useCallback(
    async (reason?: TPauseReasons) => {
      if (reason !== null && reason !== undefined && appointmentId !== null) {
        await pauseEscalationsStatus({
          patient_pause_escalation_action: reason,
          appointment_id: appointmentId,
        });

        setPausedEscalations((prev) => [
          ...prev,
          { appointmentId: appointmentId, pausedReason: reason },
        ]);

        updateEscalationsTrigger((prev) => !prev);
      }

      handleOnClose();
    },
    [
      appointmentId,
      handleOnClose,
      setPausedEscalations,
      updateEscalationsTrigger,
    ]
  );

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      maxWidth="sm"
      className="rounded-lg"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "black",
          borderRadius: "8px",
          color: "white",
          boxShadow: "0 0 10px rgba(255, 255, 255, 0.1)",
          filter: "none", // Ensure the popup is not blurred
          maxHeight: "600px",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center  bg-[#111827] text-white">
        Pause escalation {patientName ? ` for ${titleCase(patientName)}` : ""}
        <div>
          <IconButton
            onClick={handleOnClose}
            size="small"
            style={{ color: "white" }}
          >
            <Cancel />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className="flex items-center justify-center bg-[#12141e] flex-col !px-5 !py-4 gap-3 !overflow-hidden ">
        <div className="flex flex-row gap-2">
          <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="select-doctor-status-label" className="!text-white">
              Select the reason for pausing escalation
            </InputLabel>

            <Select
              labelId="select-doctor-status-label"
              id="demo-multiple-chip"
              required
              value={selectedReason?.value ?? defaultReason ?? undefined}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Select the reason for pausing escalation"
                />
              }
              onChange={handleReasonChange}
              style={{
                color: "white",
                borderColor: "white",
              }}
              className="!border-white [&>fieldset]:!border-white [&>svg]:!text-white"
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selectedReason?.label ??
                    PAUSE_ESCALATION_REASONS.find(
                      (d) => d.value === defaultReason
                    )?.label}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {PAUSE_ESCALATION_REASONS.map((doctorStat) => (
                <MenuItem
                  style={{
                    color: "white",
                    background: "#31363F",
                  }}
                  key={doctorStat.value}
                  value={doctorStat.value}
                >
                  {doctorStat.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="flex flex-row w-full justify-between mt-4">
          <Button
            onClick={handleOnClose}
            variant="text"
            color="info"
            className="!px-5 !py-2"
          >
            Cancel
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(selectedReason?.value);
            }}
            className="!px-5 !py-2"
            disabled={!selectedReason?.value}
            variant="contained"
            color="info"
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { PauseModal };
