import {
  CalendarToday as CalendarIcon,
  Cancel,
  CheckCircle,
  Close as CloseIcon,
  DirectionsWalk,
  Person as DoctorIcon,
  PrintTwoTone as PrintIcon,
} from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { Chart } from "react-google-charts";
import BackloggedDoctorsTable from "./BackLoggedDoctorsV2";
import DoctorDetailsPopup from "./DoctorDetailsPopup";
import FaultyCheckoutDetails from "./FaultyCheckoutDetails";
import {
  AppointmentData,
  Doctor,
  DoctorAppointment,
  FaultyCheckout,
  Hospital,
  OPDChamberData,
  WalkInData,
} from "./models";
import { HorizontalCheckoutCard } from "./PatientCommandCenter";

interface DoctorCommandCenterProps {
  selectedHospital: Hospital | null;
  appointmentData: AppointmentData;
  walkInData: WalkInData;
  doctors: Doctor[];
  opdData: OPDChamberData;
  faultyCheckouts: FaultyCheckout[];
}

const DoctorCommandCenter: React.FC<DoctorCommandCenterProps> = ({
  selectedHospital,
  appointmentData,
  walkInData,
  doctors,
  opdData,
  faultyCheckouts,
}) => {
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [doctorAppointments, setDoctorAppointments] = useState<
    DoctorAppointment[]
  >([]);
  const [loadingDoctorAppointments, setLoadingDoctorAppointments] =
    useState<boolean>(false);
  const [showFaultyCheckouts, setShowFaultyCheckouts] = useState(false);

  const opdChamberRef = useRef<HTMLDivElement>(null);

  // if no faulty checkouts, set count to 0
  const faultyCheckoutsCount = faultyCheckouts.filter(
    (fc) => fc.prescription_ready_time
  ).length;

  const handleDoctorClick = async (doctor: Doctor) => {
    setSelectedDoctor(doctor);
    setLoadingDoctorAppointments(true);

    try {
      const response = await fetch(
        `https://analytics-beta.maxhealthcaredigital.com/doctor-appointments?doctorId=${doctor.doctorId}&branchId=${selectedHospital?.id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch doctor appointments");
      }

      const data: DoctorAppointment[] = await response.json();
      setDoctorAppointments(data);
    } catch (error) {
      console.error("Error fetching doctor appointments:", error);
      setDoctorAppointments([]);
    } finally {
      setLoadingDoctorAppointments(false);
    }
  };

  const handleFaultyCheckoutsClick = () => {
    setShowFaultyCheckouts(true);
  };

  const handleCloseFaultyCheckouts = () => {
    setShowFaultyCheckouts(false);
  };

  const handlePrintOPDChamber = () => {
    const printWindow = window.open("", "_blank");
    if (printWindow && opdChamberRef.current) {
      printWindow.document.write(`
                <html>
                    <head>
                        <title>Away Doctor Information</title>
                        <style>
                            body { font-family: Arial, sans-serif; }
                            table { width: 100%; border-collapse: collapse; }
                            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                            th { background-color: #f2f2f2; }
                            @media print {
                                .MuiSvgIcon-root { display: none; }
                            }
                        </style>
                    </head>
                    <body>
                        ${opdChamberRef.current.innerHTML}
                    </body>
                </html>
            `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  const sortedDoctors = [...doctors].sort((a, b) => {
    return b.waitCount - a.waitCount;
  });

  const prepareDoctorStatusData = (opdData: OPDChamberData) => {
    return [
      ["Status", "Count"],
      ["In", Math.max(0, opdData.doctorIn)],
      ["Away", Math.max(0, opdData.doctorAway)],
      ["Vacant", Math.max(0, opdData.vacant)],
    ];
  };

  const getWalkInPercentageColor = (percentage: number) => {
    if (percentage < 20) return "text-green-500";
    if (percentage < 30) return "text-yellow-500";
    return "text-red-500";
  };

  const StatCard: React.FC<{
    icon: React.ReactNode;
    label: string;
    value: number | React.ReactNode;
  }> = ({ icon, label, value }) => (
    <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center justify-center h-full">
      <div className="text-3xl mb-2">{icon}</div>
      <div className="text-sm mb-1">{label}</div>
      <div className="text-3xl font-bold">{value}</div>
    </div>
  );

  const AppointmentStatWidget: React.FC<{
    appointments: AppointmentData;
    walkIns: WalkInData;
  }> = ({ appointments }) => {
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
        <StatCard
          icon={<CalendarIcon />}
          label="All"
          value={appointments.all}
        />

        <StatCard
          icon={<DirectionsWalk />}
          label="Walk-Ins"
          value={
            <>
              {walkInData.count}
              <span
                className={`ml-1 ${getWalkInPercentageColor(
                  parseInt(walkInData.percentage)
                )}`}
              >
                ({walkInData.percentage})
              </span>
            </>
          }
        />

        <StatCard
          icon={<CheckCircle />}
          label="Checked-In"
          value={appointments.checkedIn}
        />

        <StatCard
          icon={<Cancel />}
          label="Cancelled"
          value={appointments.cancelled}
        />

        <HorizontalCheckoutCard
          checkedOut={appointmentData.checkedOut}
          faultyCheckoutsCount={faultyCheckoutsCount}
          onFaultyCheckoutsClick={handleFaultyCheckoutsClick}
        />
      </div>
    );
  };

  return (
    <div
      className={`bg-black text-white min-h-screen ${
        selectedDoctor ? "blur-md" : ""
      }`}
    >
      <div className="flex flex-col lg:flex-row gap-4">
        {/* Left column - Stats and Backlogged Doctors */}
        <div className="w-full lg:w-3/4 flex flex-col gap-4">
          {/* Appointment Stats */}
          <div className="bg-gray-900 rounded-lg p-4 shadow-lg">
            <h2 className="text-lg sm:text-xl font-semibold mb-2">
              Appointment Stats
            </h2>
            <AppointmentStatWidget
              appointments={appointmentData}
              walkIns={walkInData}
            />
          </div>

          {/* Backlogged Doctors */}
          <div className="bg-gray-900 rounded-lg p-4 shadow-lg flex-grow overflow-auto">
            <BackloggedDoctorsTable
              sortedDoctors={sortedDoctors}
              opdData={opdData}
              handleDoctorClick={handleDoctorClick}
            />
          </div>
        </div>

        {/* Right column - Doctor Status and Away Doctor Information */}
        <div className="w-full lg:w-1/4 flex flex-col gap-4">
          {/* Doctor Status */}
          <div className="bg-gray-900 rounded-lg p-4 shadow-lg h-[250px]">
            <h2 className="text-lg sm:text-xl font-semibold mb-2">
              Doctor Status
            </h2>
            <div className="h-[200px]">
              <Chart
                chartType="PieChart"
                data={prepareDoctorStatusData(opdData)}
                options={{
                  backgroundColor: "transparent",
                  legend: { textStyle: { color: "white" } },
                  pieSliceText: "value",
                  colors: ["#82ca9d", "#ff7f7f", "#8884d8"],
                }}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>

          {/* Away Doctor Information */}
          <div
            className="bg-gray-900 rounded-lg p-4 shadow-lg flex-grow overflow-auto"
            ref={opdChamberRef}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg sm:text-xl font-semibold flex items-center">
                <DoctorIcon className="mr-2" />
                Away Doctor Information
              </h2>
              <IconButton
                onClick={handlePrintOPDChamber}
                className="text-white"
              >
                <PrintIcon color="primary" />
              </IconButton>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm sm:text-base">
                <thead>
                  <tr className="bg-gray-800">
                    <th className="p-2 text-left">Doctor</th>
                    <th className="p-2 text-right">Room No.</th>
                  </tr>
                </thead>
                <tbody>
                  {opdData.assignments.map((assignment, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
                      }
                    >
                      <td className="p-2">{assignment.doctorName}</td>
                      <td className="p-2 text-right">
                        #{assignment.roomNumber}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <DoctorDetailsPopup
        open={selectedDoctor !== null}
        onClose={() => setSelectedDoctor(null)}
        doctorName={selectedDoctor?.name || ""}
        roomNumber={
          [...opdData.assignments, ...opdData.inDoctors].find(
            (a) => a.doctorId === selectedDoctor?.doctorId
          )?.roomNumber || ""
        }
        appointments={doctorAppointments}
        loading={loadingDoctorAppointments}
      />

      {/* Faulty check in dialog */}
      <Dialog
        open={showFaultyCheckouts}
        onClose={handleCloseFaultyCheckouts}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#111827", // Darker background
            color: "white",
            minHeight: "80vh",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle className="flex justify-between items-center bg-gray-900 border-b border-gray-800">
          <span>Checkout Anomalies</span>
          <IconButton
            onClick={handleCloseFaultyCheckouts}
            size="small"
            style={{ color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-0 bg-gray-900">
          <div className="h-full">
            <FaultyCheckoutDetails checkouts={faultyCheckouts} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DoctorCommandCenter;
