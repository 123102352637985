import {
  TEscalationAction,
  TPauseReasons,
} from "../escalation/fetchEscalations";

export const DOCTOR_STATUS: Array<{ label: string; value: TEscalationAction }> =
  [
    { label: "Doctor Delayed", value: "DOCTOR_DELAY" },
    { label: "Doctor On Leave", value: "DOCTOR_LEAVE" },
    { label: "Manual Prescription", value: "MANUAL_PRESCRIPTION" },
    {
      label: "Doctor/Coordinator Not Reachable",
      value: "DOCTOR_NOT_REACHABLE",
    },
  ];

export const PAUSE_ESCALATION_REASONS: Array<{
  label: string;
  value: TPauseReasons;
}> = [
  { label: "Sent for cancellation → 120 mins", value: "PATIENT_CANCELLATION" },
  {
    label: "Patient consulting another doctor → 60 mins",
    value: "PATIENT_CONSULTING_DIFF_DOCTOR",
  },
  { label: "Patient not arrived → 60 mins", value: "PATIENT_NOT_ARRIVED" },
  {
    label: "Patient undergoing investigation(s) → 120 mins",
    value: "PATIENT_UNDERGOING_INVESTIGATION",
  },
];
